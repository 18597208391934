// (c) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import { useSubscriptions } from "../hooks"
import { isAccessible } from "../utils/accessible"

export default function Subscription({
  children,
  fallbackComponent = null,
  requiredSubscriptions = "",
} = {}) {
  const { loading, subscriptions: userSubscriptions } = useSubscriptions()
  const { hasSubscriptionAccess } = isAccessible({
    userSubscriptions,
    requiredSubscriptions,
  })

  const hasSubscriptionGate = requiredSubscriptions.length > 0

  // if subscriptions are required stay on page until subscriptions are loaded
  if (loading && hasSubscriptionGate) {
    return null
  }

  // redirect if subscriptions finished loading and user does not have subscription
  if (!loading && hasSubscriptionGate && !hasSubscriptionAccess) {
    return <>{fallbackComponent}</>
  }

  return children
}
