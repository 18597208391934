// (c) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import { env, isONPREM } from "./env"

const ldOptions = {
  diagnosticOptOut: true,
  fetchGoals: false,
  privateAttributes: ["email"],
  // sendEvents is required for user registration and debugging
  sendEvents: true,
  sendLDHeaders: false,
  streaming: env.REACT_APP_LD_MOCK === "true" || isONPREM() ? false : true,
}

const onPremFlags = {
  "shell-enable-analytics-1": false,
  "shell-enable-announcements-1": false,
  "shell-enable-audit-1": false,
  "shell-enable-private-cloud-ai": false,
  "shell-enable-email-notifications-1": false,
  "shell-enable-file-storage-1": false,
  "shell-enable-groups-1": false,
  "shell-enable-notifications-1": true,
  "shell-enable-objects-1": false,
  "shell-enable-rtm-1": true,
  "shell-enable-search-1": false,
  "shell-enable-sfm-1": false,
  "shell-enable-shapeshifter-1": false,
  "shell-enable-vcf-links": false,
  "shell-enable-zerto-1": false,
  "dscc-data-observability": false,
  "dscc-webhooks-service": false,
  "ezmeral-kubernetes-service": false,
  "dscc-vmware-cloud-foundation": [],
}

const cloudFlags = {
  "shell-enable-analytics-1": false,
  "shell-enable-announcements-1": false,
  "shell-enable-audit-1": false,
  "shell-enable-private-cloud-ai": false,
  "shell-enable-email-notifications-1": false,
  "shell-enable-file-storage-1": false,
  "shell-enable-groups-1": false,
  "shell-enable-notifications-1": false,
  "shell-enable-objects-1": false,
  "shell-enable-rtm-1": false,
  "shell-enable-search-1": false,
  "shell-enable-sfm-1": false,
  "shell-enable-shapeshifter-1": false,
  "shell-enable-vcf-links": false,
  "shell-enable-zerto-1": false,
  "dscc-data-observability": false,
  "dscc-webhooks-service": false,
  "ezmeral-kubernetes-service": false,
  "dscc-vmware-cloud-foundation": [],
}

// Map of flag to default value
const ldFlags = isONPREM() ? onPremFlags : cloudFlags

export const LDConfig = {
  clientSideID: env.REACT_APP_LD_CLIENTID,
  flags: ldFlags,
  options: ldOptions,
  reactOptions: { useCamelCaseFlagKeys: false },
}

export const OfflineClient = {
  allFlags: () => ldFlags,
  close: () => Promise.resolve(),
  flush: () => Promise.resolve(),
  getContext: () => ({}),
  identify: () => Promise.resolve(ldFlags),
  off: () => {},
  on: () => {},
  setStreaming: () => {},
  track: () => {},
  variation: (key, defaultValue) => ldFlags[key] ?? defaultValue,
  variationDetail: (key, defaultValue) => ({
    value: ldFlags[key] ?? defaultValue,
  }),
  waitForInitialization: () => Promise.resolve(),
  waitUntilReady: () => Promise.resolve(),
}
