// (c) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import { useFlags } from "launchdarkly-react-client-sdk"
import { useEffect } from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { Block } from "./block"
import {
  DSCCHeader,
  FeatureFlag,
  FeatureFlagOrSubscription,
  ROPHeader,
  Subscription,
} from "../components"
import { Dom } from "./dom"
import { SHELL_RTM } from "../events"
import { useWorkspace } from "../hooks"
import { Launchpad } from "./launchpad"
import { NotFound } from "./not-found"
import { DSCCMicroAppRoute, ROPMicroAppRoute, ShellRoute } from "./shell-route"
import { routes } from "../shell-routes"
import { isDSCC } from "../utils/env"
import { VCF } from "./vcf"

const headerNode = document.querySelector("greenlake-header")
const setHeaderConfig = (companyName) => {
  if (!headerNode) {
    return
  }
  headerNode.platform = "secureDefault"
  headerNode.config = {
    ...headerNode.config,
    items: [
      "brand",
      "help",
      "apps",
      "nav",
      isDSCC() && "context",
      "workspace",
    ].filter(Boolean),
  }
  if (companyName) headerNode.workspace = companyName
  headerNode.ldFlags = {
    ...headerNode.ldFlags,
    "glcp-contextual-help": true,
    "glcp-service-centric-experience-phase-1": true,
  }
}

export function AuthenticatedDSCCRoutes() {
  const {
    "shell-enable-rtm-1": shellEnableRTM1,
    "shell-enable-vcf-links": shellEnableVcfLinks,
  } = useFlags()
  const { workspace } = useWorkspace()
  setHeaderConfig(workspace.company_name)

  useEffect(() => {
    if (shellEnableRTM1) {
      window.dispatchEvent(new Event(SHELL_RTM.INIT))
    }
  }, [shellEnableRTM1])

  return useRoutes([
    {
      path: routes.launchpad.path,
      element: (
        <ShellRoute Header={DSCCHeader}>
          <Launchpad />
        </ShellRoute>
      ),
    },
    {
      path: "*",
      element: (
        <ShellRoute Header={DSCCHeader}>
          <NotFound />
        </ShellRoute>
      ),
    },
    {
      path: routes.announcements.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.announcements.flag}
        >
          <DSCCMicroAppRoute route={routes.announcements} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.atlas.path,
      element: <DSCCMicroAppRoute route={routes.atlas} />,
    },
    {
      path: routes.audit.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.audit.flag}
        >
          <DSCCMicroAppRoute route={routes.audit} />
        </FeatureFlag>
      ),
    },
    {
      element: <Block />,
      children: [
        { path: routes["block-arcus"].path, element: <Block.Arcus /> },
        { path: routes.block.path, element: <Block.Dashboard /> },
        {
          path: routes["block-data-access"].path,
          element: <Block.DataAccess />,
        },
        {
          path: routes["block-edgestack"].path,
          element: <Block.EdgeStack />,
        },
        { path: routes["block-fleet"].path, element: <Block.Fleet /> },
        { path: routes["block-nimble"].path, element: <Block.Nimble /> },
        { path: routes["block-primera"].path, element: <Block.Primera /> },
      ],
    },
    {
      path: routes.dataObservability.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.dataObservability.flag}
        >
          <DSCCMicroAppRoute route={routes.dataObservability} />
        </FeatureFlag>
      ),
    },
    {
      element: <Dom />,
      children: [
        {
          path: routes["dom-apollo-object"].path,
          element: <Dom.ApolloObject />,
        },
        { path: routes["dom-arcus"].path, element: <Dom.Arcus /> },
        { path: routes["dom-data-access"].path, element: <Dom.DataAccess /> },
        { path: routes["dom-edgestack"].path, element: <Dom.EdgeStack /> },
        { path: routes["dom-file"].path, element: <Dom.File /> },
        { path: routes["dom-fleet"].path, element: <Dom.Fleet /> },
        { path: routes["dom-homefleet"].path, element: <Dom.Homefleet /> },
        { path: routes["dom-nimble"].path, element: <Dom.Nimble /> },
        {
          path: routes["dom-objectStorage"].path,
          element: <Dom.ObjectStorage />,
        },
        { path: routes["dom-primera"].path, element: <Dom.Primera /> },
        { path: routes["dom-sds"].path, element: <Dom.SDS /> },
      ],
    },
    {
      path: routes.dualAuth.path,
      element: <DSCCMicroAppRoute route={routes.dualAuth} />,
    },
    {
      path: routes.email.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.email.flag}
        >
          <DSCCMicroAppRoute route={routes.email} />
        </FeatureFlag>
      ),
    },
    {
      path: routes["file-manager"].path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes["file-manager"].flag}
        >
          <DSCCMicroAppRoute route={routes["file-manager"]} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.groups.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.groups.flag}
        >
          <DSCCMicroAppRoute route={routes.groups} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.issues.path,
      element: <DSCCMicroAppRoute route={routes.issues} />,
    },
    {
      path: routes.kubernetesService.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.kubernetesService.flag}
        >
          <DSCCMicroAppRoute route={routes.kubernetesService} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.objectStorage.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.objectStorage.flag}
        >
          <DSCCMicroAppRoute route={routes.objectStorage} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.pcai.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.pcai.flag}
        >
          <DSCCMicroAppRoute route={routes.pcai} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.pcbe.path,
      element: <DSCCMicroAppRoute route={routes.pcbe} />,
    },
    {
      path: routes.search.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.search.flag}
        >
          <DSCCMicroAppRoute route={routes.search} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.secrets.path,
      element: <DSCCMicroAppRoute route={routes.secrets} />,
    },
    {
      path: routes.setup.path,
      element: <DSCCMicroAppRoute route={routes.setup} />,
    },
    {
      path: routes.shapeshifter.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.shapeshifter.flag}
        >
          <DSCCMicroAppRoute route={routes.shapeshifter} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.sfm.path,
      element: (
        <FeatureFlagOrSubscription
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.sfm.flag}
          requiredSubscriptions={routes.sfm.subscriptions}
        >
          <DSCCMicroAppRoute route={routes.sfm} />
        </FeatureFlagOrSubscription>
      ),
    },
    {
      path: routes.tasks.path,
      element: <DSCCMicroAppRoute route={routes.tasks} />,
    },
    {
      path: routes.vcf.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={(userLDFlags) =>
            userLDFlags[routes.links.flag] || routes.vcf.flag(userLDFlags)
          }
        >
          {shellEnableVcfLinks ? (
            <DSCCMicroAppRoute route={routes.links} />
          ) : (
            <ShellRoute Header={DSCCHeader} title={routes.vcf.title}>
              <VCF />
            </ShellRoute>
          )}
        </FeatureFlag>
      ),
    },
    {
      path: routes.webhooks.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.webhooks.flag}
        >
          <DSCCMicroAppRoute route={routes.webhooks} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.zerto.path,
      element: (
        <Subscription
          fallbackComponent={<Navigate to="/" replace />}
          requiredSubscriptions={routes.zerto.subscriptions}
        >
          <DSCCMicroAppRoute route={routes.zerto} />
        </Subscription>
      ),
    },
  ])
}

export function AuthenticatedROPRoutes() {
  const { workspace } = useWorkspace()
  setHeaderConfig(workspace.company_name)

  return useRoutes([
    {
      path: routes.launchpad.path,
      element: (
        <ShellRoute Header={ROPHeader}>
          <Launchpad />
        </ShellRoute>
      ),
    },
    {
      path: "*",
      element: (
        <ShellRoute Header={ROPHeader}>
          <NotFound />
        </ShellRoute>
      ),
    },
    {
      path: routes["allow-deny-manager"].path,
      element: <ROPMicroAppRoute route={routes["allow-deny-manager"]} />,
    },
    {
      path: routes.atlasRop.path,
      element: <ROPMicroAppRoute route={routes.atlasRop} />,
    },
    {
      path: routes.announcements.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.announcements.flag}
        >
          <ROPMicroAppRoute route={routes.announcements} />
        </FeatureFlag>
      ),
    },
    {
      path: routes.audit.path,
      element: <ROPMicroAppRoute route={routes.audit} />,
    },
    {
      path: routes["cluster-status"].path,
      element: <ROPMicroAppRoute route={routes["cluster-status"]} />,
    },
    {
      path: routes.groups.path,
      element: (
        <FeatureFlag
          fallbackComponent={<Navigate to="/" replace />}
          requiredLDFlag={routes.groups.flag}
        >
          <ROPMicroAppRoute route={routes.groups} />
        </FeatureFlag>
      ),
    },
  ])
}
