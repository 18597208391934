// (c) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import {
  Announce,
  Compliance,
  Group,
  Inherit,
  Install,
  MailOption,
  Resources,
  Secure,
  Task,
  Technology,
  Validate,
} from "grommet-icons"
import { routes } from "../../shell-routes"
import { Products, TileType } from "../../utils/constants"
import { env } from "../../utils/env"
import { getTestIdAttribute, launchpad } from "../../utils/test-ids"

/**
 * @callback requiredLDFlagsFn
 * @param {Object.<string, any>} userLDFlags User's LaunchDarkly flags
 * @returns {boolean} User has required LD flags
 */

/**
 * @callback requiredSubscriptionsFn
 * @param {Object.<string, any>} userLDFlags User's LaunchDarkly flags
 * @param {Object.<string, boolean>} userSubscriptions User's allowed subscriptions
 * @returns {boolean} User has required subscriptions
 */

/**
 * A launchpad tile
 * @typedef {Object} LaunchpadTile
 * @property {string} buttonLabel
 * @property {string} [color]
 * @property {Object.<string, string>} dataTestIds
 * @property {string|requiredLDFlagsFn} [flag]
 * @property {string} key
 * @property {function(string): any} icon
 * @property {string[]} [productTypes]
 * @property {string[]} [resources]
 * @property {string[]|requiredSubscriptionsFn} [subscriptions]
 * @property {TileType} tileType
 * @property {string} title
 * @property {string} to
 * @property {string} type
 */

/**
 * Tiles for the launchpad
 * @type {LaunchpadTile[]}
 */
const items = [
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#626B7C",
    dataTestIds: launchpad.tiles.allowDenyManager,
    description: "descriptionAllowDenyManager",
    key: routes["allow-deny-manager"].key,
    productTypes: [Products.ROP],
    resources: routes["allow-deny-manager"].resources,
    tileType: TileType.SERVICE,
    title: "titleAllowDenyManager",
    to: routes["allow-deny-manager"].navPath,
    type: routes["allow-deny-manager"].type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#7630EA",
    dataTestIds: launchpad.tiles.atlas,
    description: "descriptionAtlas",
    key: routes.atlas.key,
    productTypes: [Products.DSCC],
    resources: routes.atlas.resources,
    subscriptions: routes.atlas.subscriptions,
    tileType: TileType.SERVICE,
    title: "titleAtlas",
    to: `${routes.atlas.navPath}/dashboard`,
    type: routes.atlas.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#7630EA",
    dataTestIds: launchpad.tiles.atlasRop,
    description: "descriptionAtlasRop",
    key: routes.atlasRop.key,
    productTypes: [Products.ROP],
    resources: routes.atlasRop.resources,
    tileType: TileType.SERVICE,
    title: "titleAtlasRop",
    to: routes.atlasRop.navPath,
    type: routes.atlasRop.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#002E6D",
    dataTestIds: launchpad.tiles.block,
    description: "descriptionBlock",
    key: routes.block.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: routes.block.resources,
    tileType: TileType.SERVICE,
    title: "titleBlock",
    to: routes.block.navPath,
    type: routes.block.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#00ADEF",
    dataTestIds: launchpad.tiles.cloudPhysics,
    description: "descriptionCloudPhysics",
    key: routes.cloudPhysics.key,
    productTypes: [Products.DSCC],
    tileType: TileType.SERVICE,
    title: "titleCloudPhysics",
    to: routes.cloudPhysics.navPath,
    type: routes.cloudPhysics.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#00ADEF",
    dataTestIds: launchpad.tiles.clusterStatus,
    description: "descriptionClusterStatus",
    key: routes["cluster-status"].key,
    productTypes: [Products.ROP],
    tileType: TileType.SERVICE,
    title: "titleClusterStatus",
    to: routes["cluster-status"].navPath,
    type: routes["cluster-status"].type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#CCAAFF",
    dataTestIds: launchpad.tiles.dataObservability,
    description: "descriptionDataObservability",
    flag: routes.dataObservability.flag,
    key: routes.dataObservability.key,
    productTypes: [Products.DSCC],
    resources: routes.dataObservability.resources,
    tileType: TileType.SERVICE,
    title: "titleDataObservability",
    to: routes.dataObservability.navPath,
    type: routes.dataObservability.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#FC6161",
    dataTestIds: launchpad.tiles.zerto,
    description: "descriptionZerto",
    key: routes.zerto.key,
    productTypes: [Products.DSCC],
    resources: routes.zerto.resources,
    subscriptions: routes.zerto.subscriptions,
    tileType: TileType.SERVICE,
    title: "titleZerto",
    to: routes.zerto.navPath,
    type: routes.zerto.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#2AD2C9",
    dataTestIds: launchpad.tiles.dom,
    description: "descriptionDom",
    key: routes["dom-fleet"].key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: routes["dom-fleet"].resources,
    tileType: TileType.SERVICE,
    title: "titleDom",
    to: `${routes["dom-fleet"].navPath}/dashboard`,
    type: routes["dom-fleet"].type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#1973CB",
    dataTestIds: launchpad.tiles.fileManager,
    description: "descriptionFileManager",
    flag: routes["file-manager"].flag,
    key: routes["file-manager"].key,
    productTypes: [Products.DSCC],
    resources: routes["file-manager"].resources,
    tileType: TileType.SERVICE,
    title: "titleFileManager",
    to: routes["file-manager"].navPath,
    type: routes["file-manager"].type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#00775B",
    dataTestIds: launchpad.tiles.kubernetesService,
    description: "descriptionKubernetesService",
    flag: routes.kubernetesService.flag,
    key: routes.kubernetesService.key,
    productTypes: [Products.DSCC],
    resources: routes.kubernetesService.resources,
    tileType: TileType.SERVICE,
    title: "titleKubernetesService",
    to: routes.kubernetesService.navPath,
    type: routes.kubernetesService.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#FC9935",
    dataTestIds: launchpad.tiles.objectStorage,
    description: "descriptionObjectStorage",
    flag: routes.objectStorage.flag,
    key: routes.objectStorage.key,
    productTypes: [Products.DSCC],
    resources: routes.objectStorage.resources,
    tileType: TileType.SERVICE,
    title: "titleObjectStorage",
    to: `${routes.objectStorage.navPath}/dashboard`,
    type: routes.objectStorage.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#ACD9FF",
    dataTestIds: launchpad.tiles.pcai,
    description: "descriptionPCAI",
    flag: routes.pcai.flag,
    key: routes.pcai.key,
    productTypes: [Products.DSCC],
    tileType: TileType.SERVICE,
    title: "titlePCAI",
    to: routes.pcai.navPath,
    type: routes.pcai.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#425563",
    dataTestIds: launchpad.tiles.pcbe,
    description: "descriptionPCBe",
    key: routes.pcbe.key,
    productTypes: [Products.DSCC],
    tileType: TileType.SERVICE,
    title: "titlePCBe",
    to: routes.pcbe.navPath,
    type: routes.pcbe.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#005c8a",
    dataTestIds: launchpad.tiles.vcf,
    description: "descriptionVCF",
    flag: routes.vcf.flag,
    key: routes.vcf.key,
    productTypes: [Products.DSCC],
    tileType: TileType.SERVICE,
    title: "titleVCF",
    to: routes.vcf.navPath,
    type: routes.vcf.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#005c8a",
    dataTestIds: launchpad.tiles.links,
    description: "descriptionVCF",
    flag: routes.links.flag,
    key: routes.links.key,
    productTypes: [Products.DSCC],
    resources: routes.links.resources,
    tileType: TileType.SERVICE,
    title: "titleVCF",
    to: routes.links.navPath,
    type: routes.links.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.intentBasedProvisioning,
    description: "descriptionIntentBasedProvisioning",
    icon: (size = "xlarge") => (
      <Inherit
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.intentBasedProvisioning.icon)}
      />
    ),
    key: routes.intentBasedProvisioning.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: routes.intentBasedProvisioning.resources,
    tileType: TileType.FEATURE,
    title: "titleIntentBasedProvisioning",
    to: routes.intentBasedProvisioning.navPath,
    type: routes.intentBasedProvisioning.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.announcements,
    description: "descriptionAnnouncements",
    flag: routes.announcements.flag,
    icon: (size = "xlarge") => (
      <Announce
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.announcements.icon)}
      />
    ),
    key: routes.announcements.key,
    productTypes: [Products.DSCC, Products.ROP],
    resources: routes.announcements.resources,
    tileType: TileType.FEATURE,
    title: "titleAnnouncements",
    to: routes.announcements.navPath,
    type: routes.announcements.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.audit,
    description: "descriptionAudit",
    flag: routes.audit.flag,
    icon: (size = "xlarge") => (
      <Compliance
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.audit.icon)}
      />
    ),
    key: routes.audit.key,
    productTypes: [Products.DSCC, Products.ROP],
    resources: routes.audit.resources,
    tileType: TileType.FEATURE,
    title: "titleAudit",
    to: routes.audit.navPath,
    type: routes.audit.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.dualAuth,
    description: "descriptionDualAuth",
    icon: (size = "xlarge") => (
      <Validate
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.dualAuth.icon)}
      />
    ),
    key: routes.dualAuth.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: routes.dualAuth.resources,
    tileType: TileType.FEATURE,
    title: "titleDualAuth",
    to: routes.dualAuth.navPath,
    type: routes.dualAuth.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.email,
    description: "descriptionEmail",
    flag: routes.email.flag,
    icon: (size = "xlarge") => (
      <MailOption
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.email.icon)}
      />
    ),
    key: routes.email.key,
    productTypes: [Products.DSCC],
    tileType: TileType.FEATURE,
    title: "titleEmail",
    to: routes.email.navPath,
    type: routes.email.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.groups,
    description: "descriptionGroups",
    flag: routes.groups.flag,
    icon: (size = "xlarge") => (
      <Group
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.groups.icon)}
      />
    ),
    key: routes.groups.key,
    productTypes: [Products.DSCC, Products.ONPREM, Products.ROP],
    resources: routes.groups.resources,
    tileType: TileType.FEATURE,
    title: "titleGroups",
    to: routes.groups.navPath,
    type: routes.groups.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.secrets,
    description: "descriptionSecrets",
    icon: (size = "xlarge") => (
      <Secure
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.secrets.icon)}
      />
    ),
    key: routes.secrets.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: routes.secrets.resources,
    tileType: TileType.FEATURE,
    title: "titleSecrets",
    to: routes.secrets.navPath,
    type: routes.secrets.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.setup,
    description: "descriptionSetup",
    icon: (size = "large") => (
      <Install
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.setup.icon)}
      />
    ),
    key: routes.setup.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: routes.setup.resources,
    tileType: TileType.FEATURE,
    title: "titleSetup",
    to: routes.setup.navPath,
    type: routes.setup.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.shapeshifter,
    description: "descriptionShapeshifter",
    flag: routes.shapeshifter.flag,
    icon: (size = "xlarge") => (
      <Resources
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.shapeshifter.icon)}
      />
    ),
    key: routes.shapeshifter.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    tileType: TileType.FEATURE,
    title: "titleShapeshifter",
    to: routes.shapeshifter.navPath,
    type: routes.shapeshifter.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.tasks,
    description: "descriptionTasks",
    icon: (size = "xlarge") => (
      <Task
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.tasks.icon)}
      />
    ),
    key: routes.tasks.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: routes.tasks.resources,
    tileType: TileType.FEATURE,
    title: "titleTasks",
    to: routes.tasks.navPath,
    type: routes.tasks.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.webhooks,
    description: "descriptionWebhooks",
    flag: routes.webhooks.flag,
    icon: (size = "xlarge") => (
      <Technology
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.webhooks.icon)}
      />
    ),
    key: routes.webhooks.key,
    productTypes: [Products.DSCC],
    resources: routes.webhooks.resources,
    tileType: TileType.FEATURE,
    title: "titleWebhooks",
    to: routes.webhooks.navPath,
    type: routes.webhooks.type,
  },
]

export const launchpadItems = items.filter((item) =>
  item.productTypes.includes(env.REACT_APP_PRODUCT)
)
